import React, { useState, useCallback } from 'react'

import { CheckCircleSelectedIcon, CopyIcon } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { Select, SelectOption } from '@toasttab/buffet-pui-select'

function canDeploy({
  serviceName,
  revision,
  dockerImageUris,
  isAdhoc
}: {
  serviceName: string
  revision: string
  dockerImageUris?: string[]
  isAdhoc: boolean
}) {
  return serviceName && revision && !isAdhoc && dockerImageUris && dockerImageUris.length >= 1
}

export const DeployDestroyViaCLI = ({
  serviceName,
  revision,
  dockerImageUris,
  isAdhoc
}: {
  serviceName: string
  revision: string
  dockerImageUris?: string[]
  isAdhoc: boolean
}) => {
  const [value, setValue] = useState<SelectOption>({
      label: 'Deploy to sandbox (CLI)',
      value: `cd ~/toast/git-repos/toast-services && ./deploy_g2_service.sh -e sandbox -s ${serviceName} -r ${revision} -d 0`
    });
  const [active, setActive] = useState(false)
  const copyToClipboard = useCallback((value: SelectOption) => {
    if (active) {
      setActive(false)
      return
    }

    navigator.clipboard.writeText(value?.value || "")
    setActive(true)
    setTimeout(() => setActive(false), 2000)
  }, [active])

  if (!canDeploy({ serviceName, revision, dockerImageUris, isAdhoc })) {
    return null
  }

  const selectOptions = [
    {
      label: 'Deploy to sandbox (CLI)',
      value: `cd ~/toast/git-repos/toast-services && ./deploy_g2_service.sh -e sandbox -s ${serviceName} -r ${revision} -d 0`
    },
    {
      label: 'Deploy to production (CLI)',
      value: `cd ~/toast/git-repos/toast-services && ./deploy_g2_service.sh -e prod -s ${serviceName} -r ${revision} -d 0`
    },
    {
      label: 'Destroy in sandbox (CLI)',
      value: `cd ~/toast/git-repos/toast-services && ./destroy_g2_service.sh -e sandbox -s ${serviceName} -r ${revision} -d 0`
    },
    {
      label: 'Destroy in production (CLI)',
      value: `cd ~/toast/git-repos/toast-services && ./destroy_g2_service.sh -e prod -s ${serviceName} -r ${revision} -d 0`
    }
  ]

  return <>
    <Select<SelectOption, SelectOption> size='lg' label='' options={selectOptions} value={value} itemToValue={item => item} onChange={setValue} />
    <Tooltip>
      <Tooltip.Trigger.IconButton icon={active ? <CheckCircleSelectedIcon aria-label='Copied' /> : <CopyIcon aria-label='Copy' />} onClick={() => copyToClipboard(value)} textClassName={active ? 'text-success' : undefined} />
      <Tooltip.Content>
        {active ? <div>Command copied to clipboard!</div> : <div>Copy command to your clipboard</div>}
      </Tooltip.Content>
    </Tooltip>
  </>
}