import Duration from 'luxon/src/duration'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels
} from '@toasttab/buffet-pui-tabs'

import { BuildStatus } from '../components/BuildStatus'
import BuildStatuses from '../components/BuildStatuses'
import { DownloadTestReportsButton } from '../components/DownloadButtons'
import { fetchBuildDetails, markBuildAsAborted } from '../queries'
import LogsPanel from '../components/LogsPanel'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CommitMsg } from './CommitMsg'
import DeploymentLogsPanel from '../components/DeploymentLogsPanel'
import DeploymentInfo from '../components/DeploymentInfo'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import TestPanel from '../components/TestPanel'
import { DeployButton } from '../components/DeployButtons'
import { DeployDestroyViaCLI } from '../components/DeployDestroyViaCLI'
import TerminateConfirmationModal from '../components/TerminateConfirmationModal'
import RelatedBuilds from '../components/RelatedBuilds'
import DatadogLogo from '../images/dd_icon_rgb.png'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import cx from 'classnames'
import XRayResultsTab from '../components/XRayResultsTab'

const buildDuration = (build) =>
  (build.finishedAt || Date.now()) - build.timestamp

const isFinished = (build) => build !== null && build.finishedAt !== null

const BuildLabel = ({ children }) => (
  <label className='font-semibold'>{children}</label>
)

const BuildField = ({ children }) => (
  <div className='flex flex-row type-default'>{children}</div>
)

const DurationLabel = ({ finishedAt }) => {
  if (finishedAt) {
    return <label>Duration:</label>
  }

  return <label className='font-semibold'>Elapsed time:</label>
}

const toDeploymentInfo = (deployment, repoName) => {
  return (
    <div className='mt-3 mb-3'>
      <Link to={`/${repoName}/actions/${deployment.dobbyActionNumber}`}>
        Autodeploy action for {deployment.serviceName}
      </Link>
    </div>
  )
}

const DatadogIcon = ({ className }) => {
  return (
    <div className={cx('inline-block', className)}>
      <img
        src={DatadogLogo}
        alt='View Datadog Dashboard'
        className='h-6 p-0.5'
      />
    </div>
  )
}

const toDatadogDashboard = (repoName, buildId, startTime, endTime) => {
  var datadogDashboardLink = `https://app.datadoghq.com/dashboard/zr4-96h-f7p/bnc-tdp-generic-build-dashboard?fromUser=false&refresh_mode=paused&tpl_var_build_id=${buildId}&tpl_var_repository_name=${repoName}&from_ts=${startTime}&to_ts=${endTime}&live=true`
  return (
    <span className='mt-3 mb-3'>
      <IconButton
        icon={<DatadogIcon />}
        onClick={() => window.open(datadogDashboardLink, '_blank')}
      />
    </span>
  )
}

const BuildDetailsPage = () => {
  const { buildId, repoName } = useParams()
  const [keepFetching, setKeepFetching] = useState(true)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [isLogsPanelHidden, setIsLogsPanelHidden] = useState(false)

  const {
    data: build,
    error,
    isLoading
  } = useQuery(
    [
      'fetchBuildDetails',
      {
        buildId,
        repoName
      }
    ],
    fetchBuildDetails,
    { refetchInterval: keepFetching ? 3000 : false }
  )

  React.useEffect(() => {
    if (build && keepFetching && isFinished(build)) {
      setKeepFetching(false)
    }
  }, [keepFetching, build])

  if (isLoading) {
    return (
      <div className='pin-center'>
        <MerryGoRound />
      </div>
    )
  }

  if (error) {
    return (
      <p className='mx-4 md:mx-0'>An error happened: {JSON.stringify(error)}</p>
    )
  }

  const hasRunStatus =
    build.statuses.find((s) => s.status === 'RUNNING') !== undefined

  const showTerminateButton = !isFinished(build)

  const onConfirmTerminate = () => {
    setConfirmModalOpen(false)
    markBuildAsAborted(repoName, buildId)
  }

  const groupedStages = () => {
    let stages = build.stages.filter(
      (s) =>
        s.parentStageName === null ||
        s.parentStageName === undefined ||
        s.parentStageName === ''
    )
    const parentStageNames = [
      ...new Set(build.stages.map((s) => s.parentStageName))
    ].filter(Boolean)
    parentStageNames.forEach((stageName) => {
      console.log(stageName)
      var childStages = build.stages.filter(
        (s) => s.parentStageName === stageName
      )
      stages.forEach((stage) => {
        if (stage.name === stageName) {
          stage.children = childStages
        }
      })
    })
    return stages
  }

  return (
    <>
      <TerminateConfirmationModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => onConfirmTerminate()}
      />
      <div className='px-4 md:px-0'>
        <div className='mt-2 font-semibold type-default flex items-center overflow-auto -mx-4 md:mx-0 justify-between w-full'>
          <div className='px-4 md:px-0 min-width-full flex items-center'>
            {
              <Button
                as={Link}
                variant='text-link'
                to={`/${repoName}/builds`}
                className='!type-large'
              >
                All branches
              </Button>
            }
            <span>
              <ChevronRightIcon
                className='text-secondary mx-1'
                accessibility='decorative'
              />
            </span>
            <Button
              as={Link}
              variant='text-link'
              to={`/${build.repository}/builds/branches/${encodeURIComponent(
                build.branch
              )}`}
              className='!type-large'
            >
              {build.branch}
            </Button>
            {!!build.pullRequestUrl &&
             !!build.pullRequestNumber &&
            <>
              <span>
                <ChevronRightIcon
                  className='text-secondary mx-1'
                  accessibility='decorative'
                />
              </span>
              <Button
                as='a'
                className='!type-large'
                variant='text-link'
                href={build.pullRequestUrl}
                title={`View PR-${build.pullRequestNumber} in a new tab.`}
                target='_blank'
                rel='noreferrer noopener'
              >
                {`PR-${build.pullRequestNumber}`}
              </Button>
            </>}
            <span>
              <ChevronRightIcon
                className='text-secondary mx-1'
                accessibility='decorative'
              />
            </span>
            <Button
              as='a'
              className='!type-large'
              variant='text-link'
              href={`https://${build.gitHubHost}/${build.repositoryFullName}/commit/${build.commit}`}
              title={'View ' + build.commitMessage + ' in a new tab.'}
              target='_blank'
              rel='noreferrer noopener'
            >
              {build.commit.substring(0, 7)}
            </Button>
          </div>
          <div>
            {toDatadogDashboard(
              repoName,
              buildId,
              build.timestamp,
              build.finishedAt
            )}
          </div>
        </div>

        <div className='flex flex-row gap-2 justify-stretch'>
          <div className='flex-grow my-2 space-y-2'>
            <BuildField>
              <CommitMsg
                spaId={repoName}
                commitMsg={build.commitMessage}
                gitHubHost={build.gitHubHost}
              />
            </BuildField>
            <BuildField>
              <BuildLabel>Status:</BuildLabel>
              <BuildStatus status={build.status} />
            </BuildField>
            {!!build.buildVersion && (
              <BuildField>
                <BuildLabel>Build version:</BuildLabel>
                <div>{build.buildVersion}</div>
              </BuildField>
            )}
            {!!build.triggerReference && (
              <BuildField>
                <BuildLabel>Triggered by:</BuildLabel>
                <div>
                  {!!build.triggerReference.triggeredSystemDeepLink ? (
                    <Link to={build.triggerReference.triggeredSystemDeepLink}>
                      {build.triggerReference.triggeredSystemLinkText}
                    </Link>
                  ) : (
                    <>{build.triggerReference.triggeredSystemLinkText}</>
                  )}{' '}
                </div>
              </BuildField>
            )}
            {showTerminateButton && (
              <BuildField>
                <Button onClick={() => setConfirmModalOpen(true)} variant='destructive'>
                  Terminate build
                </Button>
              </BuildField>
            )}
          </div>
        </div>

        <h3 className='mt-3 mb-3 font-semibold type-headline-5'>
          Build steps
          <span className='mx-4 font-normal type-default'>
            <DurationLabel finishedAt={build.finishedAt} />
            {Duration.fromMillis(buildDuration(build)).toFormat(
              "m 'min' s 'sec'"
            )}
          </span>
        </h3>

        <BuildStatuses
          stages={groupedStages(build.stages)}
          statuses={build.statuses}
        />

        {!!build.autoDeploymentData &&
          build.autoDeploymentData
            .filter((d) => d.dobbyActionNumber)
            .map((d) => toDeploymentInfo(d, repoName))}

        <div className='mt-3 mb-3'>
          <div className="flex flex-row gap-4">
            <DeployButton
              repoId={build.repositoryId}
              serviceName={build.serviceName}
              revision={build.buildVersion}
              repoName={build.repository}
              dockerImageUris={build.dockerImageUris}
              isAdhoc={build.doAdhocDeploy}
              branchName={build.branch}
              shouldActivateDeploy={build.shouldActivateAdhoc}
              buildId={build.id}
            />
            <DeployDestroyViaCLI
              serviceName={build.serviceName}
              revision={build.buildVersion}
              dockerImageUris={build.dockerImageUris}
              isAdhoc={build.doAdhocDeploy}
            />
          </div>
        </div>
      </div>
      <Tabs className='w-full'>
        <TabList className='w-full px-4 md:px-0'>
          <Tab>Logs</Tab>
          <Tab>Test results</Tab>
          {!!build.deploymentStatus && <Tab>Deployment info</Tab>}
          {!!build.buildsTriggered && <Tab>Related Builds</Tab>}
        </TabList>
        <TabPanels className='py-4 md:py-6'>
          <TabPanel
            onSelection={() => setIsLogsPanelHidden(false)}
            onBlur={() => setIsLogsPanelHidden(true)}
          >
            <LogsPanel
              build={build}
              repositoryId={build.repositoryId}
              isHidden={isLogsPanelHidden}
              hasRunStatus={hasRunStatus}
            />
          </TabPanel>
          <TabPanel>
            <TestPanel
              build={build}
              lastPassingMainBranchId={build.lastSuccessfulBuildId}
            />
            {error || !build.finishedAt ? (
              hasRunStatus ? (
                <p>
                  Looks like your build is not done yet. Please check back
                  later.
                </p>
              ) : (
                <p>No tests results.</p>
              )
            ) : (
              <DownloadTestReportsButton build={build} />
            )}
          </TabPanel>
          {!!build.deploymentStatus && (
            <TabPanel>
              <DeploymentInfo build={build} />
              <DeploymentLogsPanel
                build={build}
                repositoryId={build.repositoryId}
              />
            </TabPanel>
          )}
          {!!build.buildsTriggered && (
            <TabPanel>
              <RelatedBuilds build={build} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  )
}

export default BuildDetailsPage
