import { securedFetch } from '@local/jwt-manager'

export const checkResponse = (response, followRedirect = true) => {
  if (response.redirected && !response.ok && followRedirect) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response, followRedirect = true) => {
  return checkResponse(response, followRedirect).then((r) => r.json())
}

const toText = (response) => {
  return checkResponse(response).then((r) => r.text())
}

export const downloadBinary = (url) =>
  securedFetch(url)
    .then(checkResponse)
    .then(
      (response) =>
        new Promise((resolve, reject) => {
          response
            .blob()
            .then((blob) => resolve(blob, response.headers.get('content-type')))
            .catch(reject)
        })
    )

export const downloadLogs = (params) =>
  downloadBinary(
    `/api/v1/artifacts/${params.repository}/${params.buildId}/logs`
  )

export const downloadDeploymentLogs = (params) =>
  downloadBinary(
    `/api/v1/artifacts/${params.repository}/${params.buildId}/deploymentLogs`
  )

export const downloadTestReports = (params) =>
  downloadBinary(
    `/api/v1/artifacts/${params.repository}/${params.buildId}/test-results`
  )

export const fetchAllRepositories = () =>
  securedFetch(`/api/v1/repositories`).then(toJson)

/**
 * Returns an object in the shape of https://github.toasttab.com/toasttab/tadpole-build/blob/main/tadpole-build-api/src/main/kotlin/com/toasttab/service/buildmanager/api/Build.kt
 */
export const fetchBuildDetails = (_, params) =>
  securedFetch(`/api/v1/builds/${params.repoName},${params.buildId}`).then(
    toJson
  )

export const fetchBuilds = (_, params) => {
  const query = Object.keys(params)
    .filter((k) => k !== 'repoName' && params[k] != null)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

  let url = `/api/v1/builds/${params.repoName}?${query}`
  return securedFetch(url).then(toJson)
}

export const fetchJenkinsHosts = () =>
  securedFetch('/api/v1/jenkins').then(toJson)

export const fetchLogs = (_, { jenkinsId, jobName }) =>
  securedFetch(
    `/api/v1/jenkins/${encodeURIComponent(jenkinsId)}/jobs/${jobName}/logs`
  ).then(toText)

export const fetchLogData = (_, { repoName, buildId }) => {
  return securedFetch(`/api/v1/artifacts/${repoName}/${buildId}/logData`)
    .then(toJson)
    .then((r) => {
      if (r.logText) {
        return r.logText
      }
      if (r.logLocation.indexOf('s3.amazonaws.com') > -1) {
        return fetch(r.logLocation).then(toText)
      }
      return securedFetch(r.logLocation).then(toText)
    })
}

export const fetchFinishedLogs = (_, { repoName, buildId }) =>
  securedFetch(`/api/v1/artifacts/${repoName}/${buildId}/logText`).then(toText)

export const fetchDeploymentLogs = (_, { repoName, buildId }) =>
  securedFetch(`/api/v1/artifacts/${repoName}/${buildId}/deploymentLogs`).then(
    toText
  )

export const fetchMineRepositories = () =>
  securedFetch(`/api/v1/repositories/mine`).then(toJson)

export const queryJobs = (_, { jenkinsId }) =>
  securedFetch(`/api/v1/jenkins/${jenkinsId}/jobs`).then(toJson)

export const triggerJob = (body) => {
  let headers = { 'Content-Type': 'application/json' }
  return securedFetch(`/api/v1/builds/`, {
    method: 'POST',
    body: body,
    headers: headers
  })
}

export const triggerDeploy = (body) => {
  let headers = { 'Content-Type': 'application/json' }
  return securedFetch('/v1/deploy', {
    method: 'POST',
    body: body,
    headers: headers
  }).then(toJson)
}

export const markBuildAsAborted = (repoName, buildId) =>
  securedFetch(`/api/v1/builds/${repoName},${buildId}/abort`, {
    method: 'POST'
  })

export const getTestData = (repoName, buildId) =>
  securedFetch(
    `/api/v1/artifacts/${repoName}/${buildId}/test-result-summary`
  ).then((response) => {
    var url = response.url
    return fetch(url).then((response) => toJson(response, false))
  })

export const getS3DataAsJson = (url) =>
  fetch(url).then((response) => toJson(response, false))

export const getBuildsData = () =>
  securedFetch(`/api/v1/builds/admin/`).then(toJson)
